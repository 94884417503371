
let applicant = localStorage.getItem('__applicant') != undefined ? JSON.parse(localStorage.getItem('__applicant')) : null;
let token = localStorage.getItem('__token') != undefined ? JSON.parse(localStorage.getItem('__token')) : null;
let school = localStorage.getItem('school') != undefined ? JSON.parse(localStorage.getItem('school')) : null;

const environment = window.location.hostname;
const data = {
    school: school,
    applicant: applicant != undefined ? applicant : "",
    host: 'https://api.jspnigeria.com',
    // host: 'http://192.168.105.116:8005',
    cloud: 'https://filesupload.jdlabserver.com',
    // printHost: 'https://www.jsp.ng/',
    printHost: 'https://api.tespire.ng',
    token: token != undefined ? token : '',
    // id: applicant != undefined ? applicant.id : '',
    headers: {
        Authorization: 'Bearer ' + (token != undefined ? token : ''),
        // xtenant: window.location.hostname
        xtenant: environment === 'localhost' ? 'eum.tespire.co' : environment
    }
} 




export default data 
